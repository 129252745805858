import { ArrowButton } from '@/components/Links/ArrowButton';
import React from 'react';

interface IProps {
  data: {
    title: string;
    descriptions: string[];
    link: {
      title: string;
    };
  };
  onButtonClick: () => void;
}

export const MainIntroAB = ({ data, onButtonClick }: IProps) => {
  return (
    <>
      <div
        className="
          anchor-block
          w-full min-h-[720px]
          relative overflow-x-clip
          flex flex-col items-center justify-between mobile:justify-center
          mobile:px-[28px]
          px-[16px] lg:px-[64px] px-[16px] [@media(min-width:1440px)]:px-[128px] [@media(min-width:1634px)]:px-0
        "
      >
        <div
          className="block absolute h-full w-full top-0 left-0 object-cover video-image z-[1]"
          style={{
            backgroundImage: 'linear-gradient(rgb(101, 31, 74) 0%, rgb(101, 31, 74) 57%, rgb(12, 17, 29) 100%)',
          }}
        />

        <div
          className="
              h-full w-full flex flex-col
              xl-plus:!pl-0 [@media(min-width:1634px)]:!w-[1376px]
            "
        >
          <div
            className="
                intro-content-container
                w-full
                 flex items-center justify-between pt-[110px] pb-[60px]
                lg:gap-[10px] xl-1:gap-[50px]
                xl-plus:!justify-between
                xl-mac:justify-start
              "
          >
            <div
              className="flex flex-col justify-center
                  h-full xl-1:max-w-[850px] max-w-[700px] w-full gap-[40px] relative z-50
                "
            >
              <div className="w-full flex flex-col gap-[40px] shrink-0 relative z-50">
                <h1
                  className="
                      text-white
                      text-5xl leading-[57.6px]
                      lg:text-6xl lg:leading-[72px]
                      xl-1:text-[60px] xl-1:leading-[80px]
                      relative z-10
                      [&>span]:whitespace-nowrap
                    "
                  dangerouslySetInnerHTML={{ __html: data.title }}
                />
                <div className="w-full flex flex-col gap-[12px]">
                  {data.descriptions.map((paragraph, idx) => (
                    <p
                      key={idx}
                      className="
                          max-w-[600px] text-white leading-[28px] text-[20px]
                          relative z-10
                        "
                      dangerouslySetInnerHTML={{ __html: paragraph }}
                    />
                  ))}
                </div>
              </div>

              <div className="flex items-start h-full w-full">
                <ArrowButton
                  testId="learn-more-button"
                  buttonClassname="
                flex lg:mt-0
                w-[220px] h-[53.02px] mobile:h-[48px]
                text-[17.67px] leading-[17.67px] mobile:text-base mobile:leading-[22px]
                border-white50 text-white
              "
                  buttonTextClassname="relative z-[1] leading-base flex items-center gap-[4px] group"
                  gradient="bg-conic-gradient-green"
                  buttonWrapperClassname="gap-x-[10px]"
                  gradientPosition="h-[500%]"
                  arrowVariant="secondary"
                  type="button"
                  onClick={onButtonClick}
                >
                  {data.link.title}
                </ArrowButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
