import { useCallback, useEffect, useState } from 'react';
import { isElementInViewport } from '@/helpers/blog';
import throttle from 'lodash.throttle';
import { scrollIntoView } from 'seamless-scroll-polyfill';

export const useScrollSpy = (selector = '.anchor-block', containerSelector = '#article-content') => {
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [targetElements, setTargetElements] = useState<NodeListOf<HTMLElement> | null>(null);

  const onScroll = useCallback(
    (container: HTMLElement | null, elements: NodeListOf<HTMLElement> | null) => {
      if (container && !isElementInViewport(container)) {
        setActiveItem(null);

        return;
      }

      elements?.forEach((element, index) => {
        if (activeItem !== index && isElementInViewport(element)) {
          setActiveItem(index);
        }
      });
    },
    [activeItem],
  );

  const scrollToItem = (index: number) => {
    if (targetElements && targetElements.length) {
      scrollIntoView(targetElements[index], { behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    if (!targetElements) {
      setTargetElements(document.querySelectorAll(selector));
    }

    if (!containerRef) {
      setContainerRef(document.querySelector(containerSelector) as HTMLElement);
    }

    if (containerRef && targetElements) {
      onScroll(containerRef, targetElements);
      const throttledScroll = throttle(() => onScroll(containerRef, targetElements), 200);
      document.addEventListener('scroll', throttledScroll);

      return () => {
        document.removeEventListener('scroll', throttledScroll);
      };
    }
  }, [targetElements, containerRef, onScroll, selector, containerSelector]);

  return { activeItem, scrollToItem };
};
