import { Socials } from '@/components/Socials';
import Link from 'next/link';
import Image from 'next/image';
import { SocialIcons } from '@/interfaces/navigation';

interface IProps {
  icon: SocialIcons;
  url: string;
  reviewsCount?: number;
}

export const Widget = ({ icon, url, reviewsCount }: IProps) => {
  if (!reviewsCount) return null;

  return (
    <Link
      scroll={true}
      href={url}
      target="_blank"
      className="transition hover:opacity-50"
      data-testid={`footer-social-${icon}`}
    >
      <div className="flex">
        <Socials type={icon} />
        <div className="ml-[6px] mt-[7px]">
          <Image
            src="/icons/socials/stars.svg"
            alt="star rating"
            width="69"
            height="15"
            loading="lazy"
          />
          <span className="text-lightBlue text-xs tracking-[0.4px]">{reviewsCount} REVIEWS</span>
        </div>
      </div>
    </Link>
  );
};
