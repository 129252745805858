'use client';

import { ArrowWithPlume } from '@/components/Shapes';
import { ContactFrom } from '@/components/Forms/Contact';
import './index.scss';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

interface ContactFormPopupProps {
  show: boolean;
  onClose: () => void;
}

const ContactFormPopup = ({ show, onClose }: ContactFormPopupProps) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const header = document.body.querySelector('header');

    if (show) {
      document.body.style.overflow = 'hidden';

      if (header) {
        header.style.overflowY = 'scroll';
      }
      setIsFormSubmitted(false);
    } else {
      document.body.style.overflow = 'unset';
      if (header) {
        header.style.overflowY = 'unset';
      }
    }

    return () => {
      document.body.style.overflow = 'unset';
      if (header) {
        header.style.overflowY = 'unset';
      }
    };
  }, [show]);

  return (
    <div
      className={clsx(`fixed inset-0 z-[1000] overflow-y-scroll overscroll-y-none`, {
        ['pointer-events-none no-scrollbar']: !show,
      })}
      onClick={onClose}
    >
      <div
        className={clsx(`py-[60px] px-[18px] w-full flex items-start justify-center relative`, {
          ['h-[100vh] items-center']: isFormSubmitted,
        })}
      >
        <div
          className={clsx(`absolute inset-0 z-[-1] opacity-0 bg-black opacity-duration min-h-[100vh]`, {
            ['opacity-60']: show,
          })}
        />
        <div
          className={clsx(
            `overflow-hidden sm:max-w-[680px] w-full rounded-[16px]
            py-[30px] pr-[30px] pl-[35px] sm:py-[50px] sm:pr-[65px] sm:pl-[70px] m-[10px] sm:m-[20px] duration-[700ms]
            relative bg-black-10 transform transition-transform 
            ${show ? 'translate-y-0 opacity-100' : 'translate-y-[115vh] opacity-0'}
          `,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={clsx(``, {
              ['absolute z-[-1] w-full left-0 top-0 overflow-hidden !h-full']: isFormSubmitted,
            })}
          >
            <div
              className={clsx(
                `
                  relative
                  left-[15px] [@media(min-width:640px)]:left-[-20px]
                  top-[-47px] [@media(min-width:640px)]:top-[-34px]
                `,
                {
                  ['left-[43px] [@media(min-width:640px)]:left-[32px]']: isFormSubmitted,
                },
              )}
            >
              <ArrowWithPlume
                arrowName="arrow-with-plume-pink"
                classname={`h-[150px] sm:h-[267px] w-[50px] sm:w-[67px] ${show && 'arrow-animation-small'}`}
              />
            </div>

            <div
              className="
              absolute
              bottom-[-178px] [@media(min-width:640px)]:bottom-[-243px]
              right-[197px] [@media(min-width:640px)]:right-[232px]
            "
            >
              <ArrowWithPlume
                arrowName="arrow-with-plume-pink"
                classname={`h-[250px] sm:h-[439px] w-[70px] sm:w-[110px] ${show && 'arrow-animation-large'}`}
              />
            </div>
          </div>

          <h2
            className={`
              text-[28px] sm:text-4xl text-white mb-[10px] sm:mb-[15px] text-center
          `}
          >
            {/* eslint-disable-next-line max-len */}
            Succeed <span className="font-monoItalic font-light text-purple-7 italic tracking-[-1.2px]">
              faster
            </span>{' '}
            with Syberry.
          </h2>
          <p className="text-[14px] sm:text-[22px] text-white mb-[15px] sm:mb-[25px] text-center">
            Get in touch to discuss your vision — for your software and your business.
          </p>
          <button
            className="absolute top-[15px] right-[10px] sm:right-[30px] text-grey-6 text-3xl sm:text-4xl"
            onClick={onClose}
          >
            &#10005;
          </button>
          {show && (
            <ContactFrom
              testId="contact-us-popup"
              showPotentialRole={false}
              submitButtonClassName="mt-[10px] sm:mt-[30px]"
              setFormSubmissionStatus={setIsFormSubmitted}
              popupForm
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ContactFormPopup;
