import clsx from 'clsx';

interface IProps {
  classname?: string;
  arrowName?: string;
}

export const ArrowWithPlume = ({ classname, arrowName = 'arrow-with-plume' }: IProps) => {
  return (
    <div
      role="presentation"
      className={clsx(
        `
          w-[264px] h-[1061px]
          position absolute
          transform rotate-60
          bg-no-repeat bg-center bg-contain
          pointer-events-none
        `,
        {
          [`${classname}`]: classname,
        },
      )}
      style={{ backgroundImage: `url('/backgrounds/${arrowName}.svg')` }}
    ></div>
  );
};
