import { useState, useCallback } from 'react';

export const useRemount = () => {
  const [key, setKey] = useState(0);

  const remount = useCallback(() => {
    setKey(Math.random());
  }, []);

  return { key, remount };
};
