'use client';

import { useScrollSpy } from '@/hooks/useScrollSpy';
import clsx from 'clsx';
import { Arrow } from '@/images/svg/Arrow';

interface IProps {
  list: string[];
  wrapperClassName?: string;
  amountOfListItems?: number;
}

export const NavBar = ({ list, wrapperClassName, amountOfListItems = 6 }: IProps) => {
  const { activeItem, scrollToItem } = useScrollSpy('.anchor-block', 'body');

  return (
    <div
      className={clsx('', {
        [`${wrapperClassName}`]: wrapperClassName,
        [`
          [@media(min-width:1634px)]:w-[calc((100%-1376px)/2)]
          [@media(min-width:1440px)]:flex w-[128px] 
          mt-[156px] hidden  justify-end fixed z-50
        `]: !wrapperClassName,
        [`[@media(max-height:800px)]:mt-[116px]`]: list.length > amountOfListItems,
      })}
    >
      <nav className="w-[128px]">
        <ul
          className={clsx(
            `flex flex-col gap-[10px] w-[128px] items-center [@media(min-width:1634px)]:items-start
            h-[calc(100vh-96px-60px-60px)] justify-between
          
          `,
            {
              [`[@media(max-height:800px)]:gap-[12px] max-h-[740px]`]: list.length > amountOfListItems,
              [`max-h-[600px]`]: list.length <= amountOfListItems,
            },
          )}
        >
          {list.map((listItem, index) => (
            <li
              key={listItem}
              onClick={() => scrollToItem(index)}
              className={clsx(
                `flex flex-col gap-[12px] items-center 
                 cursor-pointer duration-300 relative leading-[12px] group`,
              )}
            >
              <div className="[writing-mode:vertical-lr] rotate-180 uppercase relative ml-[2px]">
                <span
                  className={clsx(
                    'absolute top-0 left-1/2 translate-x-[-50%] duration-300 text-[10px] group-hover:text-green-7',
                    {
                      ['scale-[1.1] text-green-7 font-mono']: activeItem === index,
                      ['text-purple-6']: activeItem !== index,
                    },
                  )}
                >
                  {listItem}
                </span>

                <span
                  aria-hidden="true"
                  className="font-mono bg-blue-400 text-[12px] relative invisible"
                >
                  {listItem}
                </span>
              </div>
              <div className="h-[10px] w-[10px] relative">
                <div
                  className={clsx(
                    `w-[4px] h-[4px] rounded-full bg-purple-6 duration-300 
                    absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] group-hover:bg-green-7`,
                    {
                      ['scale-0']: activeItem === index,
                      ['scale-1']: activeItem !== index,
                    },
                  )}
                />
                <Arrow
                  variant="home-green"
                  className={clsx(
                    'rotate-90 duration-300 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]',
                    {
                      ['scale-1']: activeItem === index,
                      ['scale-0']: activeItem !== index,
                    },
                  )}
                />
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
