'use client';

import { Contact } from '@/components/Layouts/Footer/Contact';
import { Navigation } from '@/components/Layouts/Footer/Navigation';
import { CopyRights } from '@/components/Layouts/Footer/CopyRights';
import { Widget } from '@/images/svg';
import { footerContact } from '@/constants/navigations';
import Link from 'next/link';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';

export const Footer = () => {
  //TODO: Remove client hooks after adding anchor navigation to all pages
  const pathname = usePathname();

  const isCustomFooterPage = [
    '/',
    '/what-we-do/',
    '/what-we-do/custom-software-development/',
    '/what-we-do/legacy-software-modernization/',
    '/industries/healthcare/',
  ].includes(pathname);

  return (
    <footer
      className={clsx(` bg-black-11 pt-[40px]`, {
        [`[@media(min-width:1440px)]:px-[128px]`]: isCustomFooterPage,
      })}
    >
      <div
        className={clsx(` mx-auto container`, {
          [`[@media(min-width:1440px)]:px-0 [@media(min-width:1634px)]:px-[32px]`]: isCustomFooterPage,
        })}
      >
        <div className="flex gap-x-[30px] flex-col-reverse sm:gap-x-[64px] gap-y-0 sm:gap-y-xl mobile:flex-row">
          <Contact />

          <div
            className="
              block mobile:hidden h-[32px]
              border-t-[1px] border-b-white/20 border-t-[#1F242F] border-b-[1px]
              mt-[32px] mb-[24px]"
          />

          <Navigation />
        </div>
        <ul className="inline-flex gap-x-[30px] mt-[29px]">
          {footerContact.reviews.map(({ icon, url, reviewsCount }) => (
            <li key={icon}>
              <Widget
                icon={icon}
                url={url}
                reviewsCount={reviewsCount}
              />
            </li>
          ))}
        </ul>

        <div
          className="
            block mobile:hidden h-[32px]
            border-t-[1px] border-b-white/20 border-t-[#1F242F] border-b-[1px]
            mt-[32px] mb-[24px]"
        />

        <hr className="hidden mobile:block mt-[29px] sm:mt-[56px] mb-[27px] h-[1px] border-t-0 bg-white20" />

        <div className="flex justify-between flex-wrap pb-[35px] gap-base">
          <CopyRights>© {new Date().getFullYear()} Syberry. All rights reserved.</CopyRights>

          <div className="flex gap-[10px] flex-wrap items-center">
            <Link
              href="/privacy-policy/"
              className="text-sm text-quinary block hover:opacity-80 underline"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              href="/cookie-policy/"
              className="text-sm text-quinary block hover:opacity-80 underline"
              target="_blank"
            >
              Cookies Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
