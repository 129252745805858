'use client';

import data from './data.json';
import cases from '../../../app/our-work/data.json';
import { Loop } from '@/components/Common/Loop';
import { Section } from '@/components/Layouts/Section';
import { ClientsSwiper } from '@/components/Swipers/ClientsSwiper';
import { Services } from '@/components/Common/Services';
import FaqAccordion from '@/components/FaqAccordion';
import { CollapsableBanner } from '@/components/Banners/CollapsableBanner';
import VideoBackgroundIntro from '@/components/Layouts/Intros/VideoBackgroundIntro';
import { Cases } from '@/components/Common/Cases';
import { Book } from '@/components/Common/Book';
import { MainIntroAB } from '@/components/MainIntro';
import '@/components/MainIntro/index.scss';
import React from 'react';
import ContactFormPopup from '@/components/Common/ContactFormPopup';
import { IndustriesBanner } from '@/components/Banners/IndustriesBanner';
import usePopup from '@/hooks/usePopup';
import { themeColors } from '@/constants/case-studies';
import { NavBar } from '@/components/NavBar';

export const Home = () => {
  const { handleClosePopup, handleOpenPopup, isOpen } = usePopup();
  const casesList = cases.cases.list;

  return (
    <>
      <ContactFormPopup
        show={isOpen}
        onClose={handleClosePopup}
      />

      <NavBar list={['intro', 'about', 'services', 'projects', 'faq', 'contact']} />

      <MainIntroAB
        data={data.intro}
        onButtonClick={handleOpenPopup}
      />

      <VideoBackgroundIntro
        video={data.intro.video}
        image={data.intro.image}
      />

      <Loop
        id="first-loupe"
        imageClassName="
          rounded-[27px] [@media_((max-width:1440px)_and_(min-width:1280px))]:rounded-r-[0px]
        "
        onButtonClick={handleOpenPopup}
        description={data.loupe.description}
        wrapperClassName="[@media(min-width:1440px)]:!px-[128px]"
        contentClassName="[@media(min-width:1440px)]:!px-0 [@media(min-width:1634px)]:!px-[32px]"
      />

      <Section
        direction="items-center flex-col lg:flex-row"
        preTitle={data.about.preTitle}
        preTitleClassname="!mb-[10px] !text-green-7"
        image={data.about.image}
        title={data.about.title}
        classname="py-[40px] lg:pt-[40px] lg:pb-[80px] bg-black-11 relative
        [@media(min-width:1440px)]:px-[128px] anchor-block"
        contentClassname="xl:max-w-[600px]"
        innerContentClassname="
          gap-y-[30px] lg:gap-x-[60px] lg:justify-between relative z-[1]
          [@media(min-width:1440px)]:px-0 [@media(min-width:1634px)]:!px-[32px]
        "
        imageClassname="lg:mr-[5%] lg:mt-[56px]"
        titleClassname="!mb-[32px] text-white"
        tags={data.about.tags}
        readMoreHref={data.about.readMoreHref}
        video={data.about.video}
        avatar={data.about.avatar}
        content={data.about.content}
        whiteParagraphText
        animated
        id="about"
        lens={
          <svg
            width="636"
            height="1101"
            viewBox="0 0 1279 988"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-[-100px] bottom-[-400px] hidden lg:block z-[1] pointer-events-none"
          >
            <g
              style={{ mixBlendMode: 'hard-light' }}
              opacity="0.3"
              filter="url(#filter0_f_5679_29194)"
            >
              <ellipse
                cx="639.565"
                cy="494.199"
                rx="373.466"
                ry="158.163"
                transform="rotate(-22.5314 639.565 494.199)"
                fill="#2E42FF"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_5679_29194"
                x="0.343079"
                y="0.772766"
                width="1278.44"
                height="986.852"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood
                  floodOpacity="0"
                  result="BackgroundImageFix"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="144.46"
                  result="effect1_foregroundBlur_5679_29194"
                />
              </filter>
            </defs>
          </svg>
        }
      />

      <Section
        direction="items-center flex-col lg:flex-row"
        preTitle={data.clients.preTitle}
        preTitleClassname="!mb-0 !text-green-7"
        image={data.clients.image}
        title={data.clients.title}
        classname="py-[40px] lg:pt-[40px] lg:pb-[70px] bg-black-11 overflow-hidden
        [@media(min-width:1440px)]:px-[128px]"
        innerContentClassname="
          gap-y-[30px] lg:gap-x-[60px]
          [@media(min-width:1440px)]:px-0 [@media(min-width:1634px)]:!px-[32px]
        "
        titleClassname="!mb-[0] text-white"
        tags={data.clients.tags}
        readMoreHref={data.clients.readMoreHref}
        video={data.clients.video}
        avatar={data.clients.avatar}
        content={data.clients.content}
        animated
        id="clients"
      >
        <div
          className="
          h-full w-[calc(100%+32px)] min-h-[40px] flex items-end
          justify-center relative mobile:w-full bg-black-11
          [@media(max-height:850px)]:items-start
        "
        >
          <div
            className="
            w-full mobile:w-auto [@media_((max-height:850px)_and_(min-width:768px))]:pt-[30px]
            [@media_((min-width:1024px)_and_(max-height:850px))]:pt-0
          "
          >
            <ClientsSwiper
              slides={data.clients.clientsSwiper.slides}
              className="hot-lead-b"
              breakpoints={{
                1280: {
                  slidesPerView: 6,
                },
                768: {
                  slidesPerView: 5,
                },
                496: {
                  slidesPerView: 3,
                },
                400: {
                  slidesPerView: 2,
                },
              }}
            />
          </div>
        </div>
      </Section>

      <Services
        className="bg-black-11 relative overflow-x-clip [@media(min-width:1440px)]:px-[128px] anchor-block"
        innerClassName="[@media(min-width:1634px)]:px-[32px] [@media(min-width:1440px)]:px-0"
        withLens
        data={data.services}
        animated
      />

      <Book
        content={data.book.content}
        link={data.book.link}
        animated
      />

      <Cases
        data={casesList}
        viewAllCardData={cases.cases.viewAllCard}
        withButton={false}
        wrapperClassName="mobile:!pb-[40px] lg:pb-[40px] [@media(min-width:1440px)]:px-[128px] anchor-block"
        isExpandable
        themeColor={themeColors.PURPLE}
      />

      <IndustriesBanner
        wrapperClass="bg-black-11"
        contentClassname="bg-black-11"
        titleClassname="!text-green-7"
        linkTitleClassname="text-white"
      />

      <FaqAccordion
        title={data.faq.title}
        tabs={data.faq.tabs}
        videoUrl={data.faq.video.url}
        defaultValue="CustomSoftware"
        wrapperClassName="anchor-block"
      />

      <CollapsableBanner
        contactUsButtonGradient="bg-conic-gradient-green"
        animated
      />
    </>
  );
};
