import clsx from 'clsx';

export interface IProps {
  className?: string;
}

export const SlideBackground = ({ className }: IProps) => {
  return (
    <span
      className={clsx(
        `w-full h-full absolute inset-0 
        before:content-[''] before:block before:absolute before:inset-0 before:w-full before:h-full
        before:translate-x-[-100%] before:group-hover:translate-x-0 before:duration-[900ms] before:ease-linear`,
        {
          [`${className}`]: className,
        },
      )}
    />
  );
};
