/* eslint-disable  max-len */
export const XLogo = ({ className, id = '' }: { className?: string; id?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_4193_5284${id && `_${id}`})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2879 19.1667L8.66337 12.5751L2.87405 19.1667H0.424805L7.57674 11.0259L0.424805 0.833328H6.71309L11.0717 7.04584L16.5327 0.833328H18.982L12.1619 8.59706L19.5762 19.1667H13.2879ZM16.0154 17.3083H14.3665L3.93176 2.69166H5.58092L9.7601 8.54429L10.4828 9.55988L16.0154 17.3083Z"
          fill="#98A2B3"
          className={className}
        />
      </g>
      <defs>
        <clipPath id={`clip0_4193_5284${id && `_${id}`}`}>
          <rect
            width="20"
            height="20"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
