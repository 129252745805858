'use client';

import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import React, { useRef, useState } from 'react';
import { AnimationBlock } from '@/components/AnimationBlock';
import { Arrow } from '@/images/svg/Arrow';
import Image, { ImageProps } from 'next/image';
import clsx from 'clsx';
import { FlipAnimationBlock } from '@/components/FlipAnimationBlock';
import { themeColors } from '@/constants/case-studies';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

export interface IAdditionalInfo {
  title: string | null;
  image: ImageProps | null;
  description: string | null;
}

export interface ICaseStudyCardData {
  id: string;
  title: string;
  href: string;
  categories: string[];
  image?: ImageProps;
  description: string;
  tools?: {
    list: { title: string; href: string | null }[];
    more: number | null;
  };
  tags: {
    title: string;
    href?: string | null;
  }[];
  additionalData?: IAdditionalInfo;
}

export interface ICaseStudyCard {
  id: string;
  title?: string;
  preTitle?: string;
  tags?: string[];
  description?: string;
  href?: string;
  image?: ImageProps;
  video?: {
    src: string;
  };
  themeColor: themeColors;
  animated?: boolean;
  isExpandable: boolean;
  isFlipAnimation: boolean;
  index: number;
}

export const CaseCard = ({
  id,
  tags,
  href,
  title,
  image,
  video,
  preTitle,
  themeColor,
  description,
  animated = false,
  isExpandable = false,
  isFlipAnimation = false,
  index,
}: ICaseStudyCard) => {
  const blockRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  const flipDelay = 200;
  const fadeDelay = 800;

  const playVideo = (delay: number) => {
    if (video && !isPlaying) {
      const timeout = setTimeout(() => {
        setPlaying(true);
      }, delay);

      return () => clearTimeout(timeout);
    }
  };

  const card = (
    <Link
      href={href || ''}
      ref={blockRef}
      className="group flex justify-center"
    >
      <div
        className={clsx(
          `
          min-h-[583px] flex flex-col max-w-[440px] w-full relative group
          cursor-pointer hover:scale-[1.05] duration-300 overflow-hidden rounded-[20px]
          shadow-lg z-[10]
        `,
          {
            ['bg-green-9']: themeColor === themeColors.GREEN,
            ['bg-purple-14']: themeColor === themeColors.PURPLE,
            ['bg-purple-15']: themeColor === themeColors.PINK,
            ['bg-purple-9']: themeColor === themeColors.DARK_PINK,
          },
        )}
      >
        <div
          className={clsx(`h-[289px] w-full !pointer-events-none`, {
            [`bg-black-11`]: themeColor === 'green' || themeColor === 'darkPink',
          })}
        >
          <>
            {video && (
              <ReactPlayer
                url={video.src}
                autoPlay={false}
                controls={false}
                playsinline
                playing={isPlaying}
                muted
                width="100%"
                height="100%"
                className="!w-full !h-full bg-purple-14 !pointer-events-none [&>video]:object-cover"
                fallback={
                  <Image
                    src="/case-studies/logo.webp"
                    alt="Logo"
                    loading="lazy"
                    width={443}
                    height={249}
                  />
                }
              />
            )}
            {image && (
              <Image
                src={image.src}
                width={image.width}
                height={image.height}
                alt={image.alt || 'Logo'}
                loading="lazy"
                placeholder="empty"
                className={clsx(`object-cover w-full h-full`, {
                  [`group-hover:opacity-0 duration-[1000ms]`]: isExpandable,
                })}
              />
            )}
          </>
        </div>
        <div
          className={clsx(
            `
            flex flex-col px-[24px] pt-[24px] absolute top-0 left-0
            translate-y-[289px] z-10
          `,
            {
              ['bg-green-9']: themeColor === 'green',
              ['bg-purple-14']: themeColor === 'purple',
              ['bg-purple-15']: themeColor === 'pink',
              ['bg-purple-9']: themeColor === 'darkPink',
              [`group-hover:translate-y-[-2px] duration-500`]: isExpandable,
            },
          )}
        >
          <div
            className={clsx(`w-full h-[87px] absolute top-0 translate-y-[-87px] z-0`, {
              [`group-hover:translate-y-0 duration-500`]: isExpandable,
            })}
          >
            <svg
              width="140"
              height="87"
              viewBox="0 0 140 87"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute bottom-[-2px] right-[18px]"
            >
              <path
                d="M140 0V87H0L140 0Z"
                fill={clsx('', {
                  ['#239745']: themeColor === themeColors.GREEN,
                  ['#3A275A']: themeColor === themeColors.PURPLE,
                  ['#943175']: themeColor === themeColors.PINK,
                  ['#862963']: themeColor === themeColors.DARK_PINK,
                })}
              />
            </svg>
          </div>

          {preTitle && (
            <div
              className={clsx('leading-[24px] text-base uppercase font-medium mb-[12px] relative z-10', {
                ['text-grey-9']: themeColor === themeColors.GREEN,
                ['text-purple-7']: themeColor === themeColors.PURPLE,
                ['text-green-7']: themeColor === themeColors.PINK || themeColor === themeColors.DARK_PINK,
              })}
            >
              {preTitle}
            </div>
          )}
          {title && (
            <p
              className="
              text-grey-1 leading-[35px] text-[30px] mobile:text-4xl
              mobile:leading-[43.2px] mb-[20px] relative z-10 line-clamp-3
            "
            >
              {title}
            </p>
          )}
          {description && (
            <div
              className="
              leading-[25.6px] text-base opacity-0 line-clamp-[12]
              group-hover:opacity-[100] duration-500 text-white relative z-10
              min-h-[200px]
            "
            >
              {description}
            </div>
          )}
        </div>
        <div className="flex-1 w-full" />
        <div
          className={clsx(`w-full px-[24px] py-[32px] flex justify-between relative z-10`, {
            ['bg-green-9']: themeColor === themeColors.GREEN,
            ['bg-purple-14']: themeColor === themeColors.PURPLE,
            ['bg-purple-15']: themeColor === themeColors.PINK,
            ['bg-purple-9']: themeColor === themeColors.DARK_PINK,
          })}
        >
          <div className="flex justify-between items-center">
            {tags && (
              <object className="flex gap-[8px] flex-wrap overflow-visible">
                {tags.map((tag) => {
                  return (
                    <Link
                      key={tag}
                      href={{
                        pathname: routeNames.ourWork,
                        query: { category: tag, page: 1 },
                      }}
                    >
                      <span
                        className="
                        transition-colors duration-300 ease-out text-[14px]
                        flex items-center justify-center bg-transparent
                        rounded-full border-[1px] px-[10px] py-[1px]
                        text-center hover:bg-white/10
                        text-white border-white
                        leading-[20px]
                      "
                      >
                        {tag}
                      </span>
                    </Link>
                  );
                })}
              </object>
            )}
          </div>
          <div className="opacity-[0] group-hover:opacity-[100] duration-500 flex items-center">
            <span
              className={clsx('', {
                ['text-white']: themeColor === themeColors.GREEN || themeColor === themeColors.PINK,
                ['text-green-7']: themeColor === themeColors.PURPLE || themeColor === themeColors.DARK_PINK,
              })}
            >
              Expand
            </span>
            <Arrow
              variant={`${
                themeColor === themeColors.PURPLE || themeColor === themeColors.DARK_PINK ? 'home-green' : 'secondary'
              }`}
              className="group-hover:translate-x-[5px] duration-300"
            />
          </div>
        </div>
      </div>
    </Link>
  );

  if (animated && isFlipAnimation) {
    return (
      <FlipAnimationBlock
        index={index}
        id={`card-anim-flip-${id}`}
        callback={() => {
          playVideo((index + 1) * flipDelay);
        }}
        backfaceSlot={
          <div
            className={clsx(
              `backface-hidden rotate-y-180 absolute w-full h-full inset-0 rounded-[20px] mx-auto max-w-[440px]`,
              {
                ['bg-green-9']: themeColor === themeColors.GREEN,
                ['bg-purple-14']: themeColor === themeColors.PURPLE,
                ['bg-purple-15']: themeColor === themeColors.PINK,
                ['bg-purple-9']: themeColor === themeColors.DARK_PINK,
              },
            )}
          />
        }
      >
        {card}
      </FlipAnimationBlock>
    );
  }

  return (
    <>
      {animated ? (
        <AnimationBlock
          id={`card-anim-${id}`}
          blockRef={blockRef}
          callback={() => {
            playVideo(fadeDelay);
          }}
        >
          {card}
        </AnimationBlock>
      ) : (
        card
      )}
    </>
  );
};
