import caseData from '../../app/our-work/data.json';
import { TCaseStudiesIds } from '@/interfaces/caseStudies';
import { ICardPreview } from '@/interfaces/layout';

export const caseList = caseData.cases.list as ICardPreview[];
export const getCasesById = (ids?: TCaseStudiesIds[]) => {
  if (!ids) return caseList;

  return caseList.filter((caseItem) => {
    return ids.find((id) => id === caseItem.id);
  });
};

const sortMainPreview = () => {
  const copiedCases = JSON.parse(JSON.stringify(caseList)) as ICardPreview[];

  return copiedCases.sort((a, b) => {
    // eslint-disable-next-line
    // @ts-expect-error
    if (a?.mainPreviewOrder > b?.mainPreviewOrder || !a?.mainPreviewOrder) {
      return 1;
    }

    return -1;
  });
};

export const getAllPreviewCases = () => {
  return sortMainPreview().map((caseItem: ICardPreview) => {
    return {
      ...caseItem,
      tools: null,
    };
  });
};

type TObjectWithIdField = { id: string | number };

export const filterAndSortByIds = <T extends TObjectWithIdField>(items: T[], ids: string[]): T[] =>
  items
    .filter((item) => ids.includes(String(item.id)))
    .sort((a, b) => ids.indexOf(String(a.id)) - ids.indexOf(String(b.id)));

export const filterAndSortTags = (tags: string[], reference: string[]): string[] =>
  tags
    .filter((tag) => reference.some((ref) => ref.toLowerCase() === tag.toLowerCase()))
    .sort((a, b) => reference.indexOf(a.toLowerCase()) - reference.indexOf(b.toLowerCase()));
