import { IArticleAuthor } from '@/interfaces/blog';
import { XLogo } from '@/images/svg/logos/XLogo';
import { FacebookLogo } from '@/images/svg/logos/FacebookLogo';
import { LinkedinLogo } from '@/images/svg/logos/LinkedinLogo';

export const articleAuthors: IArticleAuthor[] = [
  {
    id: '1',
    name: 'Yev Kedrun',
    role: 'Account Director',
    image: '/images/blog/authors/author-1.png',
    linked: 'https://www.linkedin.com/in/kedrun/',
  },
  {
    id: '2',
    name: 'Diana Isaian',
    role: 'Senior Marketing Manager',
    image: '/images/blog/authors/author-2.png',
    linked: 'https://www.linkedin.com/in/diana-isaian-976207173/',
  },
  {
    id: '3',
    name: 'Timour Procopovich',
    role: 'Executive Vice President',
    image: '/images/blog/authors/author-3.png',
    linked: 'https://www.linkedin.com/in/timour-procopovich/',
  },
  {
    id: '4',
    name: 'Sergey Laptik',
    role: 'Vice President',
    image: '/images/blog/authors/author-4.png',
    linked: 'https://www.linkedin.com/in/slaptik/',
  },
  {
    id: '5',
    name: 'Anastasia Panteley',
    role: 'Brand & Communications Specialist',
    image: '/images/blog/authors/author-5.png',
  },
  {
    id: '6',
    name: 'Olga Dyuba',
    role: 'Project Manager',
    image: '/images/blog/authors/author-6.png',
    linked: 'https://www.linkedin.com/in/olya-dyuba/',
  },
  {
    id: '7',
    name: 'Darya Yurevich',
    role: 'Account Executive',
    image: '/images/blog/authors/author-7.png',
    linked: 'https://www.linkedin.com/in/darya-yurevich-5a9568140/',
  },
  {
    id: '8',
    name: 'Matt Pinckley',
    role: 'Account Executive',
    image: '/images/blog/authors/author-8.png',
    linked: 'https://www.linkedin.com/in/pinckley/',
  },
  {
    id: '9',
    name: 'Anna Ilyina',
    role: 'Account Executive',
    image: '/images/blog/authors/author-9.png',
    linked: 'https://www.linkedin.com/in/anna-i-736825137/',
  },
  {
    id: '10',
    name: 'Eugene Fesko',
    role: 'Account Executive',
    image: '/images/blog/authors/author-10.png',
    linked: 'https://www.linkedin.com/in/eugene-fesko-12455b157/',
  },
  {
    id: '11',
    name: 'Paul Vasiliev',
    role: 'CTO and Co-CEO',
    image: '/images/blog/authors/author-11.png',
    linked: 'https://www.linkedin.com/in/paul-vasiliev/',
  },
  {
    id: '12',
    name: 'Elena Laptik',
    role: 'Head of QA',
    image: '/images/blog/authors/author-12.png',
    linked: 'https://www.linkedin.com/in/elena-laptik/',
  },
  {
    id: '13',
    name: 'Roman Kukhta',
    role: 'Account Executive',
    image: '/images/blog/authors/author-13.png',
    linked: 'https://www.linkedin.com/in/roman-kukhta-27b048141/',
  },
  {
    id: '14',
    name: 'Ekaterina Goryaeva',
    role: 'Systems Analyst',
    image: '/images/blog/authors/author-14.png',
    linked: 'https://www.linkedin.com/in/ekaterina-goryaeva/',
  },
];

export const getSocialLinks = (mobileView = false) => [
  {
    name: 'Twitter(X)',
    iconComponent: (
      <XLogo
        className="group-hover:fill-black-14 transition-[fill] duration-300"
        {...(mobileView && { mobile: 'mobile' })}
      />
    ),
    url: 'https://twitter.com/intent/tweet?url=',
  },
  {
    name: 'Facebook',
    iconComponent: (
      <FacebookLogo
        className="group-hover:fill-black-14 transition-[fill] duration-300"
        {...(mobileView && { id: 'mobile' })}
      />
    ),
    url: 'https://www.facebook.com/sharer/sharer.php?u=#',
  },
  {
    name: 'LinkedIn',
    iconComponent: (
      <LinkedinLogo
        className="group-hover:fill-black-14 transition-[fill] duration-300"
        {...(mobileView && { id: 'mobile' })}
      />
    ),
    url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  },
];

export const TABLE_OF_CONTENTS_HIGHLIGHT_OFFSET = 120;
