'use client';

import { CaseCard, ICaseStudyCardData } from '@/components/Cards/CaseCard';
import { Lens } from './Lens';
import clsx from 'clsx';
import {
  themeColors,
  initialCases,
  defaultIndustries,
  industriesTagImages,
  industriesOptions,
  industryCategories,
  includedCases,
} from '@/constants/case-studies';
import { useMemo, useRef, useState } from 'react';
import { useRemount } from '@/hooks/useRemount';
import { ImageProps } from 'next/image';
import { Dropdown } from '@/components/Dropdown';
import { Tag } from '@/components/Insights/Tags/components/Tag';
import { filterAndSortByIds } from '@/helpers/caseStudies';
import { AnimationBlock } from '@/components/AnimationBlock';
import { useScreenSize } from '@/hooks/useScreenSize';

interface IProps {
  data: ICaseStudyCardData[];
  viewAllCardData: {
    href: string;
    title: string;
    preTitle: string;
    tags: string[];
    image: ImageProps;
  };
  link: {
    to: string;
    title: string;
  };
  withButton?: boolean;
  withLens?: boolean;
  titleSlot?: boolean;
  wrapperClassName?: string;
  innerClassName?: string;
  isExpandable?: boolean;
  themeColor: themeColors;
}

export const Cases = ({
  data,
  viewAllCardData,
  withLens = true,
  titleSlot = false,
  wrapperClassName,
  innerClassName,
  isExpandable = false,
  themeColor = themeColors.PURPLE,
}: IProps) => {
  const blockRef = useRef(null);
  const screenSize = useScreenSize();
  const [activeTag, setActiveTag] = useState<industryCategories | null>(null);
  const [isInitialAnimation, changeAnimationType] = useState(true);
  const { key, remount } = useRemount();

  const initialData = filterAndSortByIds(data, initialCases);

  const filteredData = useMemo(() => {
    if (!activeTag) {
      return initialData;
    }

    const includedCaseIds = includedCases[activeTag] || [];
    const result = data.filter((item) => includedCaseIds.includes(item.id));

    return result.length ? result : initialData;
  }, [activeTag, data, initialData]);

  const handleTagClick = (tag?: industryCategories | null) => {
    remount();
    setActiveTag(tag ?? null);
    if (isInitialAnimation) {
      changeAnimationType(false);
    }
  };

  return (
    <div
      className={clsx(
        `bg-black-11 flex flex-col justify-center relative overflow-x-clip gap-[40px] scroll-mt-[96px]
        mobile:gap-[55px] items-center pt-[40px] pb-[40px] mobile:pb-[80px] px-[16px]`,
        {
          [`${wrapperClassName}`]: wrapperClassName,
        },
      )}
    >
      <div className="w-full">
        {titleSlot && (
          <div className="p-base flex justify-center w-full mb-[24px]">
            <div className="w-full max-w-[1376px]">
              <h3 className="intro-pre-title mb-xs !mb-0 !text-green-7">Our Projects</h3>
            </div>
          </div>
        )}

        <AnimationBlock
          id="tools-anim"
          blockRef={blockRef}
          className="w-full flex justify-center"
        >
          <div
            ref={blockRef}
            className={clsx(
              `
                flex lg-2:justify-between w-full max-w-[1376px] items-center relative z-[20] gap-base justify-end
              `,
              {
                [`${innerClassName}`]: innerClassName,
              },
            )}
          >
            <ul className="lg:flex flex-row gap-[12px] text-white shrink-0 hidden">
              <li>
                <Tag
                  tagName="Overview"
                  handleClick={() => handleTagClick(null)}
                  isActive={!activeTag}
                  themeColor={themeColor}
                  wrapperClassName="!px-[20px] !py-[12px] !leading-[20px] border-white/30"
                />
              </li>
              {defaultIndustries.slice(0, 4).map((tag) => {
                return (
                  <li
                    onClick={() => handleTagClick(tag)}
                    key={tag}
                  >
                    <Tag
                      tagName={tag}
                      handleClick={() => handleTagClick(tag)}
                      tagsMap={industriesTagImages}
                      isActive={tag === activeTag}
                      themeColor={themeColor}
                      wrapperClassName="!px-[20px] !py-[12px] !gap-0 !font-light !border-[0.5px] border-white/30"
                    />
                  </li>
                );
              })}
            </ul>
            <div className="max-w-[440px] w-full">
              <Dropdown
                options={
                  screenSize.width <= 1024 ? industriesOptions : industriesOptions.slice(4, industriesOptions.length)
                }
                setDropdownValue={handleTagClick}
                value={activeTag}
                themeColor={themeColor}
                placeholder="Filter by industries"
              />
            </div>
          </div>
        </AnimationBlock>
      </div>
      <div
        key={key}
        className={clsx(
          `
                relative justify-center max-w-[1376px] w-full md-tablet:w-auto lg-2:w-full gap-[28px]
          grid grid-cols-1 md-tablet:grid-cols-2 lg-2:grid-cols-3 z-10
              `,
          {
            [`${innerClassName}`]: innerClassName,
          },
        )}
      >
        {filteredData.map(({ id, title, description, additionalData, tags, href, categories }, index) => (
          <CaseCard
            id={id}
            key={id}
            animated
            href={href}
            index={index}
            tags={categories}
            themeColor={themeColor}
            isExpandable={isExpandable}
            isFlipAnimation={!isInitialAnimation}
            title={additionalData?.title || title}
            description={additionalData?.description || description}
            preTitle={tags.map((tag) => tag.title).join(', ')}
            image={
              additionalData?.image || {
                src: '/case-studies/logo.webp',
                height: 249,
                width: 443,
                alt: 'Logo',
              }
            }
          />
        ))}
        <CaseCard
          id="view-all-card"
          key="view-all-card"
          animated
          href={viewAllCardData.href}
          themeColor={themeColor === themeColors.PURPLE ? themeColors.DARK_PINK : themeColors.GREEN}
          isExpandable={false}
          preTitle={viewAllCardData.preTitle}
          index={filteredData.length}
          tags={viewAllCardData.tags}
          title={viewAllCardData.title}
          isFlipAnimation={!isInitialAnimation}
          video={{
            src: '/videos/logo-opener.mp4',
          }}
        />
      </div>
      {withLens && <Lens />}
    </div>
  );
};
