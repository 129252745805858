export const POTENTIAL_CLIENT_OPTION = 'Potential client';
export const POTENTIAL_EMPLOYEE_OPTION = 'Potential employee';

export enum FormTypes {
  CONTACT_US = 'contactUs',
  APPLY_VACANCY = 'applyVacancy',
  NOTIFY_ME = 'notifyMe',
}

export const FORMS = {
  contactUs: {
    id: 'contact-form',
    type: null,
    controls: [
      {
        name: 'name',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'last-name',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'phone',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'email',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'company',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'potential-role',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'additional-info',
        isRequired: true,
        isPrefilled: false,
      },
    ],
    specificErrorMessages: [
      {
        isSuccess: false,
        reason: 'reCaptcha', //Back-end validation
        message: `Something went wrong. reCaptcha v3 error. 
          Please reload the page or send your request to <a href="mailto:contact@syberry.com">contact@syberry.com</a>.`,
      },
      {
        isSuccess: true,
        reason: 'characters', //Back-end validation
        message: "Thanks! Your form has been submitted.<br> We'll be in touch soon.",
      },
      {
        isSuccess: true,
        reason: 'forbidden word', //Back-end validation
        message: "Thanks! Your form has been submitted.<br> We'll be in touch soon.",
      },
      {
        isSuccess: true,
        reason: 'language', //Back-end validation
        message: "Thanks! Your form has been submitted.<br> We'll be in touch soon.",
      },
      {
        isSuccess: true,
        reason: 'potential role', //Back-end validation
        message: "Thanks! Your form has been submitted.<br> We'll be in touch soon.",
      },
    ],
    successMessage: "Thanks! Your form has been submitted.<br> We'll be in touch soon.",
    errorMessage: `Something went wrong. Please reload the page or send your request to
      <a href="mailto:contact@syberry.com">contact@syberry.com</a>.`,
    potentialRoleOptions: [
      {
        label: POTENTIAL_CLIENT_OPTION,
        value: POTENTIAL_CLIENT_OPTION,
      },
      {
        label: POTENTIAL_EMPLOYEE_OPTION,
        value: POTENTIAL_EMPLOYEE_OPTION,
      },
    ],
  },
  notifyMe: {
    id: 'notify-me-form',
    type: 'notify-me',
    controls: [
      {
        name: 'email',
        isRequired: true,
        isPrefilled: false,
      },
    ],
    specificErrorMessages: [
      {
        isSuccess: false,
        reason: 'reCaptcha', //Back-end validation
        message: `Something went wrong. reCaptcha v3 error. 
          Please reload the page or send your request to <a href="mailto:contact@syberry.com">contact@syberry.com</a>.`,
      },
    ],
    successMessage: 'Thanks! We will notify you when we open the registration for the new event.',
    errorMessage: 'Something went wrong. Please reload the page',
  },
  applyVacancy: {
    id: 'apply-vacancy-form',
    type: 'apply-vacancy',
    controls: [
      {
        name: 'first-name',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'last-name',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'email',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'country',
        isRequired: true,
        isPrefilled: false,
      },
      {
        name: 'additional-info',
        isRequired: false,
        isPrefilled: false,
      },
    ],
    specificErrorMessages: [
      {
        isSuccess: false,
        reason: 'reCaptcha', //Back-end validation
        message: `Something went wrong. reCaptcha v3 error. 
          Please reload the page or send your request to <a href="mailto:contact@syberry.com">contact@syberry.com</a>.`,
      },
    ],
    successMessage: 'Your request has been received! Please check your e-mail for confirmation.',
    errorMessage: 'Something went wrong. Please reload the page',
  },
};
