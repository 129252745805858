import data from '../app/insights/data.json';
import { IInsightListItem, TInsightIds } from '@/interfaces/insights';

export const getInsightsById = (ids: TInsightIds[], list: IInsightListItem[] = data.insights.list) => {
  return ids.reduce((insights: IInsightListItem[], id) => {
    const foundedInsight = list.find((listItem) => listItem.id === id);

    if (foundedInsight) {
      insights.push(foundedInsight);
    }

    return insights;
  }, []);
};

export const getUniqueValues = (array: string[]) =>
  array.filter((value, index, array) => array.indexOf(value) === index);
