import clsx from 'clsx';
import Image from 'next/image';
import { themeColors } from '@/constants/case-studies';

interface IProps {
  tagName: string;
  handleClick: (tag: string) => void;
  isActive?: boolean;
  themeColor?: themeColors;
  tagsMap?: any;
  withCrossIcon?: boolean;
  wrapperClassName?: string;
}

export const Tag = ({
  tagName,
  handleClick,
  tagsMap,
  wrapperClassName,
  themeColor = themeColors.WHITE,
  isActive = false,
  withCrossIcon = false,
}: IProps) => {
  return (
    <div
      onClick={() => handleClick(tagName)}
      className={clsx(
        `cursor-pointer border-[1px] rounded-full font-medium text-center leading-lg
         flex gap-[4px] items-center active:scale-[0.96] duration-300
         mobile:py-[8px] mobile:px-base mobile:text-base
         py-[2px] px-[8px] text-xs select-none
        `,
        {
          ['border-black-4 bg-white text-black-10 hover:border-purple-5']: themeColor === 'white',
          ['!border-purple-2 !bg-purple-2 !text-purple-8 hover:!bg-purple-1']: themeColor === 'white' && isActive,
          ['border-purple-2 bg-purple-2 !text-purple-8 hover:bg-purple-1']:
            (themeColor === 'purple' || themeColor === 'pink') && isActive,
          ['border-black-4 bg-purple-14 text-white hover:border-purple-5']: themeColor === 'purple',
          ['border-black-4 bg-purple-15 text-white hover:border-purple-5']: themeColor === 'pink',
          [`${wrapperClassName}`]: wrapperClassName,
        },
      )}
    >
      {tagsMap && tagsMap(tagName) && (
        <Image
          src={tagsMap(tagName)}
          alt={tagName}
          width={20}
          height={20}
          className="mr-[8px] w-[20px] h-[20px]"
        />
      )}
      {tagName}

      {withCrossIcon && (
        <div className="bg-[url(/icons/tag-cross.svg)] w-[12px] h-[12px] bg-contain bg-center bg-no-repeat" />
      )}
    </div>
  );
};
