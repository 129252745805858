import Select, { GroupBase, SingleValueProps, ValueContainerProps } from 'react-select';
import { OptionProps } from 'react-select';
import { industryCategories, IOptionType, themeColors } from '@/constants/case-studies';
import clsx from 'clsx';

interface IProps {
  options: IOptionType[];
  setDropdownValue: (value?: industryCategories | null) => void;
  value?: string | null;
  themeColor?: themeColors;
  placeholder?: string;
}

export const Dropdown = ({
  options,
  setDropdownValue,
  value,
  placeholder,
  themeColor = themeColors.PURPLE,
}: IProps) => {
  return (
    <Select<IOptionType>
      components={{
        Option: ({ data, innerProps, isSelected }: OptionProps<IOptionType, false, GroupBase<IOptionType>>) => {
          return (
            <div
              className={clsx('flex gap-[8px] items-center px-xs py-[8px] cursor-pointer', {
                ['!bg-purple-15']: themeColor === 'purple' && isSelected,
                ['hover:!bg-purple-13']: themeColor === 'purple' && !isSelected,
                ['!bg-green-9']: themeColor === 'pink' && isSelected,
                ['hover:!bg-purple-16']: themeColor === 'pink' && !isSelected,
              })}
              {...innerProps}
            >
              <div
                className="h-[20px] w-[20px] bg-center bg-no-repeat bg-contain"
                style={{
                  backgroundImage: `url(${data.image.src})`,
                }}
              />
              <div className="first-letter:uppercase text-white">{data.label}</div>
            </div>
          );
        },
        SingleValue: ({ data, innerProps }: SingleValueProps<IOptionType, false, GroupBase<IOptionType>>) => {
          return (
            <div
              className="flex gap-[8px] items-center"
              {...innerProps}
            >
              <div
                className="h-[20px] w-[20px] bg-center bg-no-repeat bg-contain"
                style={{
                  backgroundImage: `url(${data.image.src})`,
                }}
              />
              <div className="first-letter:uppercase text-white">{data.label}</div>
            </div>
          );
        },
        ValueContainer: ({ children, innerProps }: ValueContainerProps<IOptionType, false, GroupBase<IOptionType>>) => {
          return (
            <div
              className="flex items-center gap-[8px] px-[10px] py-[4px]"
              {...innerProps}
            >
              {children}
            </div>
          );
        },
        IndicatorSeparator: null,
      }}
      placeholder={placeholder}
      className="relative z-50"
      options={options}
      isSearchable={false}
      isClearable={false}
      isMulti={false}
      isRtl={false}
      onChange={(option) => {
        setDropdownValue(option?.value);
      }}
      value={value ? options.find((option) => option.value === value) ?? null : null}
      classNames={{
        control: ({ isFocused }) => `
          ${themeColor === themeColors.PURPLE ? '!bg-purple-14' : ''}
          ${themeColor === themeColors.PINK ? '!bg-purple-15' : ''}
          !border-white/30 !border-[1px] !cursor-pointer !h-[42px]
          ${isFocused ? `!shadow-none !border-transparent` : ''}
        `,
        placeholder: () => '!text-white',
        menuList: () => `
          ${themeColor === themeColors.PURPLE ? '!bg-purple-14' : ''} 
          ${themeColor === themeColors.PINK ? '!bg-purple-15' : ''} 
          !rounded-[4px] !border-white/30 !border-[1px] !p-0 !no-scrollbar`,
        option: ({ isSelected, isFocused }) => `
          !cursor-pointer !text-white !text-sm  
          ${isFocused && '!bg-white10'}
          ${isSelected && '!bg-red-600'}
        `,
        dropdownIndicator: ({ isFocused }) => `
          ${isFocused ? '!text-white rotate-180' : '!text-white/50'}
        `,
      }}
    />
  );
};
